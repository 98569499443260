body,
#root,
html {
  font-family: "Roboto", Arial, sans-serif;
  /* color: #1c1e21; */
  overflow-wrap: break-word;
  text-rendering: optimizeLegibility;
  transition: background 500ms ease-in-out, color 200ms ease;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  word-wrap: break-word;
  word-break: normal;
  font-weight: 400;
  color: #0e1311;
  max-width: 100%;
  overflow-x: hidden;
  line-height: 24px;
}

p {
  font-size: 18px;
  line-height: 28px;
  margin: 0;
}

@media (min-width: 1200px) {
  .container {
    max-width: 970px;
  }
}

p {
  /* font-size: 14px; */
}

h1 {
  font-weight: 700;
  color: #0e1311;
  font-size: 36px;
  line-height: 42px !important;
}

h2 {
  font-weight: 500;
  font-size: 28px;
}
.navbar-light .navbar-nav .nav-link {
  gap: 15px;
  display: flex;
  align-items: center;
  transition: 0.3s;
  height: 100%;
  font-size: 14px;
  text-transform: none;
  padding: 5px;
  margin: 0 10px;
  font-weight: 500;
  color: #0e1311;
}

label {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 5px !important;
}

.errorMessage {
  font-weight: 600;
  font-size: 14px;
  color: red !important;
}

.successMessage {
  font-weight: 600;
  font-size: 14px;
  color: green !important;
}

.navbar-light .navbar-nav .nav-link {
  font-size: 15px;
  font-weight: 600;
  color: #14253f;
  text-decoration: none;
}

#navbarScroll .rbt-input-main {
  background-image: url("/public/images/icons/search.png");
  background-repeat: no-repeat;
  background-position: 10px 8px;
  background-size: 14px 14px;
  padding-left: 35px;
}
